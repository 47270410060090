import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { GET_REGISTRY_FOR_PICKUP, GET_ONLINE_ORDER_BY_WISHLIST_ID } from "../api/queries";
import { useApolloClient, useMutation } from "@apollo/client";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import numeral from "numeral";
import format from "date-fns/format";
//import TrTidDialog from "./TrTidDialog";
import { addDays, isAfter } from "date-fns";
import MessageIcon from "@mui/icons-material/Message";
import Popover from "@mui/material/Popover";
import { PAYMENT_TYPE, getStatusLabel } from "../../../constants";
import { AppContext } from "../../core/AppContext";
import { SEARCH_GIFT_WRAP } from "../../giftwrap/api/queries";
import { UPDATE_SHOPIFY_CHECKOUT_STATUS } from "../../registry/api/mutations";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 20,
  },
  card: {
    padding: 15,
  },
  orderDetail: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  orderItems: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tableIndex: {
    fontWeight: "bold",
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  label: {
    width: "30%",
    fontWeight: "800",
    verticalAlign: "top",
  },
  value: {
    width: "70%",
    verticalAlign: "top",
  },
  cartItemTable: {
    fontSize: "10pt",
    marginTop: 30,
    width: "100%",
    borderCollapse: "collapse",
  },
  cartItemQty: {
    width: "5%",
    padding: ".50rem",
    verticalAlign: "top",
    display: "table-cell",
  },
  tr: {
    display: "table-row",
  },
  th: {
    padding: ".50rem",
    verticalAlign: "top",
    display: "table-cell",
  },
  cartItemCode: {
    width: "15%",
    padding: ".50rem",
    verticalAlign: "top",
    display: "table-cell",
  },
  cartItemDesc: {
    width: "20%",
    padding: ".50rem",
    border: "1px solid #dee2e6",
    verticalAlign: "top",
    display: "table-cell",
  },
  cartItemPrice: {
    width: "5%",
    padding: ".50rem",
    border: "1px solid #dee2e6",
    verticalAlign: "top",
    display: "table-cell",
    textAlign: "right",
  },
  cartItemDiscount: {
    width: "5%",
    textAlign: "right",
    padding: ".50rem",
    verticalAlign: "top",
    display: "table-cell",
  },
  cartItemAmt: {
    width: "5%",
    textAlign: "right",
    padding: ".50rem",
    verticalAlign: "top",
    display: "table-cell",
  },
  actions: {
    width: "5%",
    textAlign: "right",
    padding: ".50rem",
    verticalAlign: "top",
    display: "table-cell",
  },
}));

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  tableIndex: {
    fontWeight: "bold",
    textAlign: "center",
  },
  image: {
    width: 60,
    height: "auto",
  },
  typography: {
    padding: theme.spacing(2),
    whiteSpace: "pre-wrap",
  },
}));

let STATUSES = [
  {
    text: 'TO PAY',
    value: 'TO_PAY'
  },
  {
    text: 'CONFIRMED',
    value: 'CONFIRMED'
  },
  {
    text: 'TO RECEIVE',
    value: 'TO_RECEIVE'
  },
  {
    text: 'DELIVERED',
    value: 'DELIVERED'
  },
  {
    text: 'COMPLETED',
    value: 'COMPLETED'
  },
  {
    text: 'REJECTED',
    value: 'REJECTED'
  },
  {
    text: 'FOR REFUND',
    value: 'FOR_REFUND'
  },
  {
    text: 'REFUNDED',
    value: 'REFUNDED'
  }
]

let ACTIVE_STATUSES = STATUSES

const Row = (props) => {
  let { checkoutRow, idx, doFetchData, giftWraps, registry } = props;
  const [checkout, setCheckout] = useState();
  const [open, setOpen] = useState(false);
  const [pickCompleted, setPickCompleted] = useState(false);
  const [openTr, setOpenTr] = useState(false);
  const classes = useRowStyles();
  const [init, setInit] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [doUpdateShopifyCheckoutStatus] = useMutation(UPDATE_SHOPIFY_CHECKOUT_STATUS);
  const { showSnackBar } = useContext(AppContext);

  const isPickCompleted = (updatedCheckout) => {
    const pickIndex = updatedCheckout.checkoutItems.findIndex((el) => el.picked === 0);
    if (pickIndex === -1) {
      setPickCompleted(true);
    } else {
      setPickCompleted(false);
    }
  };

  const initialize = () => {
    if (!init) {
      isPickCompleted(checkoutRow);
      setInit(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dedicationOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!checkout) {
      setCheckout(checkoutRow);
      initialize();
    }
  }, [checkoutRow]);

  const giftWrap = giftWraps.reduce((selectedWrap, wrap) => {
    if (wrap.isDefault) {
      selectedWrap = wrap;
    }
    if (wrap._id == checkoutRow.giftWrap) {
      selectedWrap = wrap;
    }
    return selectedWrap;
  }, {});

  const doSaveCheckoutStatus = async (checkout, evt) => {
    checkout.orderStatus = evt.target.value

    const { loading } = await doUpdateShopifyCheckoutStatus({
      variables: {
        shopifyCheckoutId: checkout?._id,
        status: checkout.orderStatus,
      },
    });
    if (!loading) {
      setOpen(false);
      doFetchData();
      showSnackBar({
        open: true,
        message: "Status successfully updated!",
        severity: "info",
      });
    }
  };

  if(registry?.isPickup){
    ACTIVE_STATUSES = STATUSES.filter((v) => v.text !== 'DELIVERED')
  }

  // console.log('checkout?.orderStatus', checkout?.orderStatus)
  if(['CONFIRMED','TO_RECEIVE','DELIVERED','COMPLETED'].includes(checkout?.orderStatus)){
    ACTIVE_STATUSES = STATUSES.filter((v) => v.value !== 'TO_PAY')
  } 

  return (
    <>
      {checkout ? (
        <Fragment>
          <TableRow key={idx}>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell onClick={() => setOpen(!open)} align="center">
              {checkout?.checkout?.order?.orderNumber}
            </TableCell>
            <TableCell onClick={() => setOpen(!open)} align="center">
              {checkout?.checkout?.order?.trackingNumber}
            </TableCell>
            <TableCell onClick={() => setOpen(!open)} align="center">
              {format(new Date(checkout.checkout.createdAt), "MMM, dd yyyy")}
            </TableCell>
            <TableCell onClick={() => setOpen(!open)} align="center">
              {checkout.customer ? checkout.customer.firstName : ""}{" "}
              {checkout.customer ? checkout.customer.lastName : ""}
            </TableCell>
            <TableCell onClick={() => setOpen(!open)} align="center">
              { checkout?.paymentTransactions?.transactions ? checkout?.paymentTransactions?.transactions[0]?.gateway : ''}
            </TableCell>
            {/* <TableCell>
              {checkout.tr || ""} / {checkout.tid || ""}
            </TableCell> */}
            <TableCell onClick={() => setOpen(!open)} align="center">
              {numeral(checkout.checkout.totalPrice.amount).format("0,000.00")}
            </TableCell>
            <TableCell onClick={() => setOpen(!open)} align="center">
              {numeral(checkout?.availableShippingRates?.shippingRates?.[0]?.price?.amount).format("0,000.00")}
            </TableCell>
            <TableCell align="center">
              { checkout.orderStatus}
              {/* {
                isEditStatus ? 
                <FormControl variant="outlined">
                  <Select
                    name="subType"
                    onChange={(evt) => { 
                      doSaveCheckoutStatus(checkout, evt)
                      setIsEditStatus(!isEditStatus)
                    }
                  }
                    value={checkout.orderStatus}
                  >
                    {ACTIVE_STATUSES.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> :
                getStatusLabel(checkout.orderStatus)
              } 
              
              { !isEditStatus ?
                <IconButton
                  aria-label="delete"
                  onClick={() => setIsEditStatus(!isEditStatus)}
                >
                  <EditIcon /> 
                </IconButton> : <IconButton
                  aria-label="cancel"
                  onClick={() => setIsEditStatus(!isEditStatus)}
                >
                  <CancelIcon /> 
                </IconButton>
              }  */}
            </TableCell>
            <TableCell onClick={() => setOpen(!open)} align="center">
              { checkout?.guestBarcode}
            </TableCell>
            {/* <TableCell align="center">
              <Button
                size="small"
                variant="outlined"
                color={pickCompleted ? "primary" : "secondary"}
                onClick={() => setOpen(!open)}
              >
                {pickCompleted ? "Completed" : "Incomplete"}
              </Button>
            </TableCell> */}
            {/* <TableCell align="right"> */}
            {/* {checkout.dedication && (
                <>
                  <IconButton color="primary" component="span" onClick={handleClick} size="large">
                    <MessageIcon />
                  </IconButton>
                  <Popover
                    id={checkout.referenceNo}
                    open={dedicationOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Typography className={classes.typography}>{checkout.dedication}</Typography>
                  </Popover>
                </> */}
            {/* { !pickCompleted && <IconButton  onClick={ () => { window.open(`/printForPosting/${checkout._id}`, '_blank') }}>
                    <PrintIcon />
                </IconButton> } */}
            {/* <Button size="small" variant="contained" color="secondary" onClick={evt => { window.open(`/printForPosting/${checkout._id}`, '_blank') }}>Print</Button> */}
            {/* </TableCell> */}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "rgb(232, 248, 255)" }} colSpan={10}>
              <Collapse in={open} timeout="auto" unmountOnExit style={{}}>
                <Box margin={1}>
                  <h3>Items for picking</h3>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={5} />
                        <TableCell className={classes.tableIndex}>Quantity</TableCell>
                        <TableCell className={classes.tableIndex}>Product</TableCell>
                        <TableCell className={classes.tableIndex}>Gross Amount</TableCell>
                        <TableCell className={classes.tableIndex}>Discount</TableCell>
                        <TableCell className={classes.tableIndex}>Net Amount</TableCell>
                        {/* <TableCell className={classes.tableIndex} width={5} /> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {checkout.checkoutItems.map((checkoutItem, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <img className={classes.image} src={checkoutItem.image || "/static/no-image.jpg"} />
                          </TableCell>
                          <TableCell align="center">{numeral(checkoutItem.node.quantity).format("0,000")}</TableCell>
                          <TableCell align="center">
                            {checkoutItem.node.title ?? checkoutItem.productName} <br />
                            {checkoutItem.node.variant.sku ?? checkoutItem.skuCode}
                          </TableCell>
                          <TableCell align="center">
                            &#8369;
                            {numeral(checkoutItem?.node?.variant?.price?.amount).format("0,000.00")}
                          </TableCell>
                          <TableCell align="center">
                            &#8369;{numeral(checkoutItem?.node?.discountAllocations?.[0]?.allocatedAmount.amount).format("0,000.00")}
                          </TableCell>
                          <TableCell align="center">
                            &#8369;
                            { 
                              numeral(checkoutItem?.node?.variant?.price?.amount).format("0,000.00") - 
                              numeral(checkoutItem?.node?.discountAllocations?.[0]?.allocatedAmount.amount).format("0,000.00")
                            }
                          </TableCell>
                          {/* <TableCell>
                            {checkoutItem.picked === checkoutItem.quantity ? "Picked" : "Unpicked"}
                            
                          </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <TableRow style={{ display: "flex", justifyContent: "start" }}>
                  <TableCell>
                    <Box margin={1}>
                      <p>Gift Tag/Wrap</p>
                      {checkout.giftTag ? (
                        <img
                          src={`/static/gifttag/${checkout.giftTag}.jpg`}
                          placeholder={checkout.giftTag}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                      ) : (
                        <img
                          src={`/static/gifttag/with_tag.jpg`}
                          placeholder={checkout.giftTag}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                      )}{" "}
                      {giftWrap && giftWrap.images && giftWrap.images.length > 0 ? (
                        <img
                          src={giftWrap.images[0].url}
                          laceholder={checkout.giftWrap}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                      ) : (
                        checkout.giftWrap && (
                          <img
                            src={`/static/giftwrap/default-wrap.jpeg`}
                            laceholder={checkout.giftWrap}
                            style={{ width: 50, height: 50, objectFit: "cover" }}
                          />
                        )
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box margin={1}>
                      <p>Gift Message</p>
                      {checkout.dedication}
                    </Box>
                  </TableCell>
                </TableRow>
              </Collapse>
            </TableCell>
          </TableRow>
          {/* <TrTidDialog
            openTr={openTr}
            setOpenTr={setOpenTr}
            checkout={checkoutRow}
            setCheckout={setCheckout}
            doFetchData={doFetchData}
          /> */}
        </Fragment>
      ) : null}
    </>
  );
};

const PickList = (props) => {
  const { showProgressIndicator } = useContext(AppContext);
  const { id, packed, pickComplete, registry, setRegistry } = props;
  const classes = useStyles();
  const [checkouts, setCheckouts] = useState();
  const [isOpenConfirmPack, setIsOpenConfirmPack] = useState(false);
  const client = useApolloClient();
  const [disabled, setDisabled] = useState(true);
  const [isForPicking, setIsForPicking] = useState(false);
  const [giftWrapList, setGiftWrapList] = useState([]);

  const doFetchData = async () => {
    try {
      showProgressIndicator(true);
      const { data, loading } = await client.query({
        query: GET_ONLINE_ORDER_BY_WISHLIST_ID,
        variables: { id },
        fetchPolicy: "network-only",
      });

      if (!loading && data.getOnlineOrdersByWishlistId) {
        const { getOnlineOrdersByWishlistId } = data;
        showProgressIndicator(false);
        setCheckouts(JSON.parse(getOnlineOrdersByWishlistId));
        return JSON.parse(getOnlineOrdersByWishlistId)
      }
    } catch (e) {}
    showProgressIndicator(false);
  };

  const fetchGiftWraps = async () => {
    try {
      showProgressIndicator(true);
      const { data, loading } = await client.query({
        query: SEARCH_GIFT_WRAP,
        variables: {
          searchInput: {},
          cursor: 0,
          order: "asc",
          orderBy: "productName",
          limit: 10,
        },
        fetchPolicy: "network-only",
      });

      if (!loading) {
        const { giftWraps } = JSON.parse(data?.searchGiftWrap);
        setGiftWrapList(giftWraps);
      }
    } catch (e) {}
    showProgressIndicator(false);
  };

  const fetchData = useCallback(async () => {
    if (!checkouts) {
      doFetchData();
    }
    if (!giftWrapList || giftWrapList.length == 0) {
      fetchGiftWraps();
    }
  });

  const checkTrDetailsComplete = () => {
    if (checkouts) {
      const isCompleteCheck = checkouts.findIndex((el) => el.tr === "" || !el.tr);
      if (isCompleteCheck === -1) {
        return true;
      }
      return false;
    }
  };

  const checkComplete = () => {
    const updatedCheckOuts = [...checkouts];
    let pickIndex = 0;
    for (let i = 0; i < updatedCheckOuts.length; i++) {
      const upCheckout = updatedCheckOuts[i];
      pickIndex = upCheckout.checxkoutItems.findIndex((el) => el.picked === 0);
      if (pickIndex !== -1) {
        break;
      }
    }

    if (pickIndex === -1) {
      setRegistry({ ...registry, status: "PICK_COMPLETE" });
      pickComplete(true);
    } else {
      setRegistry({ ...registry, status: "PART_PICKED" });
      pickComplete(false);
    }
  };

  const packConfirmation = async () => {
    if (checkTrDetailsComplete()) {
      await setPacked();
    } else {
      setIsOpenConfirmPack(true);
    }
  };

  const setPacked = async () => {
    setIsOpenConfirmPack(false);
    const updatedReg = { ...registry };
    updatedReg.status = "PACKED";
    packed();
    setRegistry(updatedReg);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, id]);

  useEffect(() => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    setDisabled(["PICK_COMPLETE", "PACKED", "SHIPPED", "CLOSE"].includes(registry.status));
    const twoDays = addDays(today, 2);
    setIsForPicking(isAfter(twoDays, new Date(registry.deliveryDate)));
  }, [registry]);

  const handleFilterEvent = async (evt) => {
    const { value } = evt.target;
    
    const checkoutss = await doFetchData()
    console.log('checkouts', checkouts)
    let filteredCheckoutItems = checkoutss.filter(
      (e) =>
        [e?.checkout?.order?.trackingNumber].includes(value) ||
        [e?.checkout?.order?.orderNumber].includes(value) ||
        (value === null || value === "")
    );
    setCheckouts(filteredCheckoutItems);
  };

  return (
    <Card className={classes.card} elevation={1}>
      <TextField 
        placeholder="Search for Order # / Tracking #" 
        fullWidth
        onChange={(evt) => handleFilterEvent(evt)}
        sx={{ width: 350, marginBottom: 2 }}
      ></TextField>
      {checkouts && checkouts.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableIndex}>Order #</TableCell>
                <TableCell className={classes.tableIndex}>Tracking #</TableCell>
                <TableCell className={classes.tableIndex}>Date</TableCell>
                <TableCell className={classes.tableIndex}>Customer</TableCell>
                <TableCell className={classes.tableIndex}>Payment Type</TableCell>
                {/* <TableCell className={classes.tableIndex}>TR/TID</TableCell> */}
                <TableCell className={classes.tableIndex}>Amount Paid</TableCell>
                <TableCell className={classes.tableIndex}>Shipping</TableCell>
                <TableCell className={classes.tableIndex}>Status</TableCell>
                <TableCell className={classes.tableIndex}>Guest Barcode</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {checkouts.map((checkout, idx) => (
                <Row
                  key={idx}
                  idx={idx}
                  checkoutRow={checkout}
                  pickComplete={pickComplete}
                  registry={registry}
                  setRegistry={setRegistry}
                  checkComplete={checkComplete}
                  doFetchData={doFetchData}
                  disabled={disabled}
                  giftWraps={giftWrapList}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <br />
      <br />
      <br />

      {/* { registry && ["PICK_COMPLETE"].includes(registry.status) && <div>
            <Button variant="contained" color="secondary" onClick={() => packConfirmation()} disabled={!isForPicking}>PACKED</Button> 
            {isForPicking ? null : <><br/><span style={{fontSize:12, color:"#858585"}}>Delivery Date is still more than 2 days to go</span></>}
        </div>} */}

      <Dialog open={isOpenConfirmPack} onClose={() => setIsOpenConfirmPack(false)}>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            There are still unposted orders. Would you like to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenConfirmPack(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setPacked()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PickList;
